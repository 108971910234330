body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  background: #fff;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

code {
  font-family: 'Poppins', sans-serif;
}

a,
.anchor {
  color: #23304e;
  text-decoration: none;
  cursor: pointer;
}

input,
textarea {
  outline: none;
  resize: none;
}

button {
  outline: none;
}

pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}

MuiPagination-ul li::before {
  content: none;
}

@font-face {
  font-family: 'DMSans';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/DMSans-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'DMSans';
  font-style: normal;
  font-weight: 200;
  src: url('assets/fonts/DMSans-ExtraLight.ttf') format('opentype');
}

@font-face {
  font-family: 'HansomFY';
  src: url('assets/fonts/HansomFY-Regular_S.ttf') format('opentype');
}

@font-face {
  font-family: 'DMSans';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/DMSans-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'DMSans';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/DMSans-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/Hind-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/Hind-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/Hind-Semibold.ttf') format('opentype');
}

@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/Hind-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Etelka';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/etelkaLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Etelka';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/etelkaMedium.woff2') format('woff2');
}

@font-face {
  font-family: 'Etelka';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/etelkatextpro.woff2') format('woff2');
}

@font-face {
  font-family: 'Etelka';
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/etelkatextprobold.woff2') format('woff2');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/OpenSans-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/OpenSans-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/OpenSans-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/Quattrocento-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/Quattrocento-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Ppformula';
  src: url('assets/fonts/PPFormula-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* https://best-font.com/
https://best-font.com/fonts/download-frutiger-lt-std-45-light-font.html */
@font-face {
  font-family: 'FrutigetLTStd';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/Frutiger-LT-Std-45-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'FrutigetLTStd';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/Frutiger-LT-Std-55-Roman.ttf') format('opentype');
}

@font-face {
  font-family: 'FrutigetLTStd';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/Frutiger-LT-Std-65-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/Nexa-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/Nexa-Bold.otf') format('opentype');
}
